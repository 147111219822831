export const sukoaUtils = {
    ajxGet(url, fallback=null){
        let req = new XMLHttpRequest();
        req.onreadystatechange = function() {
            if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                if(fallback) {
                    fallback(req);
                }
            }
        };
        req.open('GET', url, true);
        req.send(null);
    },

    each(list, fn) {
        if(list && fn) {
            for(let i = 0; i < list.length; i++) {
                fn(list[i]);
            }
        }
    },

    getCurrentURL : function(excludeParams){
        let url = window.location.href;
        if(excludeParams){
            let i = url.indexOf("?");
            if(i>-1){
                url = url.substring(0,i);
            }
            let j = url.indexOf("#");
            if(j>-1){
                url = url.substring(0,j);
            }
        }
        return url;
    },

    getParameterByName: function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    updateDetailUrl: function (detail){
        let dtlName = detail.src.replace("#item_detail_", "");
        let currentUrl = sukoaUtils.getCurrentURL();
        let nUrl;

        if (currentUrl.includes(dtlName)){
            nUrl = currentUrl.replace("/"+dtlName, "");
        } else{
            nUrl = currentUrl.replace(".html", "/"+dtlName+".html");
        }

        if (nUrl){
            window.history.pushState({}, '', nUrl);
        }
    },
    /* decryp addresses*/
     dcmadr(nnnn){
        let a = "";
        for(let i=0,m=nnnn.length;i < m;i++){
            if(i%3==0){
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href=(a);
    },

    isEditMode() {return document.body.classList.contains('editmode')},
    getSiteLang() {return document.documentElement.lang},

    isGoogleBot(){
        let botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
        let re = new RegExp(botPattern, 'i');
        let userAgent = navigator.userAgent;
        if (re.test(userAgent)) {
            return true;
        }
        return false;
    }
};

// module.exports = sukoaUtils;
